import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

const ImageBlocks = () => {
    return (
        <div className="pb-14 lg:py-28 bg-strong space-y-10 lg:space-y-28 relative -top-[8rem]">
            <div className="container-full flex flex-col lg:flex-row space-y-10 lg:space-x-28 items-center">
                <div className="flex w-full lg:w-2/4">
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        alt="Hero image"
                        layout="constrained"
                        aspectRatio={4 / 3}
                        placeholder="blurred"
                        src={'../../images/Performance-photo-1.jpeg'}
                        formats={['auto', 'webp', 'avif']}
                    />
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <p className="container lg:p-0 text-light">
                            Our exhibition, ‘Performance in Code: Deciphering
                            Value in Generative Art’, was designed to answer
                            that question by demonstrating the value that
                            blockchain technology can bring to both artists and
                            art collectors, while encouraging visitors to step
                            into the world of Web3 art by creating a Tezos
                            wallet and minting an NFT.
                            <br />
                            <br />
                            We built the exhibition around a display of
                            generative digital art from three of the foremost
                            artists working in this new medium. Rather than
                            being passive viewers, we invited attendees to
                            become collectors, by gifting them unique pieces
                            from the evolving collections as NFTs. Newly-minted
                            NFTs were awarded a rarity score based on the
                            characteristics contained in the work, and the wall
                            displays updated in response, creating a unique
                            visual experience for every one of the 9000+
                            visitors.
                        </p>
                    </Fade>
                </div>
            </div>
            <div className="container-full flex flex-col lg:flex-row-reverse space-x-reverse space-y-10 lg:space-x-reverse lg:space-x-28 items-center">
                <div className="flex w-full lg:w-2/4">
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        alt="Hero image"
                        layout="constrained"
                        aspectRatio={4 / 3}
                        placeholder="blurred"
                        src={'../../images/Performance-photo-2.png'}
                        formats={['auto', 'webp', 'avif']}
                    />
                </div>
                <div className="flex w-full lg:w-2/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <p className="container lg:p-0 text-light">
                            Using dynamic displays, giant projected dashboards
                            and live minting technology, we designed
                            ‘Performance in Code’ as a venue not only for the
                            appreciation of great art, but also conversation,
                            where patrons were invited to consider how the
                            concepts of rarity and value interrelate.
                            <br />
                            <br />
                            The exhibition only occupied a physical space for
                            four days, but the collections minted at the Tezos
                            booth at Art Basel Miami Beach will be discussed
                            among communities of digital art collectors and art
                            enthusiasts online for a long time to come.
                        </p>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { ImageBlocks }

import React from 'react'
import ReactPlayer from 'react-player'

const CenteredVideo = ({ video_url }) => {
    return (
        <div className="container mx-auto" id="video">
            <div className={'video-container'}>
                <div className={'react-player-wrapper mx-auto'}>
                    <ReactPlayer
                        playsinline={false}
                        background={1}
                        url={video_url}
                        controls={true}
                        width="100%"
                        height="100%"
                        className={'react-player-rounded'}
                        loop={false}
                    />
                </div>
            </div>
        </div>
    )
}

export { CenteredVideo }

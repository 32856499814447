import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { HeroTitle } from './HeroTitle'
import { HeroSubtitle } from './HeroSubtitle'

const Hero = ({ children }) => {
    return (
        <>
            <div className="w-full h-screen relative">
                {children}
                <div className="absolute bottom-1/4 w-full">
                    <div className="container mx-auto">
                        <div className="w-full lg:w-2/4 grid gap-y-12">
                            <div className="row-span-1">
                                <Fade direction="up" triggerOnce delay={300}>
                                    <HeroTitle />
                                </Fade>
                            </div>
                            <div className="row-span-1">
                                <Fade direction="up" triggerOnce delay={200}>
                                    <HeroSubtitle />
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Hero }

import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Head } from '../components/MetaInfo'
import { Hero } from '../components/Hero'
import { HeroVideo } from '../components/HeroVideo'
import { CallToAction } from '../components/CallToAction'
import { ImageBlocks } from '../components/ImageBlocks'
import { Steps } from '../components/Steps'
import { Cards } from '../components/Cards'
import { StatsVideoSection } from '../components/StatsVideoSection'
import { Button } from '../components/Button'

const App = () => {
    return (
        <>
            <Head
                title="Art Basel"
                description="Where art, creativity and data collide: Tezos captures the attention of the traditional art world at Art Basel Miami Beach. Our ‘Performance in Code’ exhibition provided a venue not only for the appreciation of great art, but also conversation, where patrons were invited to consider how the concepts of rarity and value interrelate."
            />
            <main>
                <Hero>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        layout="constrained"
                        placeholder="blurred"
                        alt="Hero image"
                        transformOptions="fit"
                        src={'../images/hero_image.jpeg'}
                        formats={['auto', 'webp', 'avif']}
                        className="static_override"
                    />
                </Hero>
                <HeroVideo
                    video_url={
                        'https://player.vimeo.com/video/780828338?h=dab26551cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                    }
                />
                <CallToAction title="Where art, creativity and data collide: Tezos captures the attention of the traditional art world at Art Basel Miami Beach">
                    <p className="text-black">
                        For the past 50 years, Art Basel has served as a
                        showcase for the latest trends in the world of art,
                        attracting a passionate community of enthusiasts and
                        collectors to events held all over the globe. In 2021,
                        the energy-efficient blockchain Tezos made headlines when it
                        presented the first NFT exhibition in Art Basel’s
                        history, introducing the traditional art world to the
                        potential of digital and generative art.
                        <br />
                        <br />
                        Art Basel Miami Beach 2022 took place at an important
                        juncture for the crypto industry. With the market in a
                        slump, and the highly-publicized collapse of companies
                        like FTX still generating headlines, critics in the art
                        world were questioning whether crypto had anything
                        useful to offer.
                    </p>
                </CallToAction>
                <ImageBlocks />
                <Steps />
                <CallToAction title="A seamless digital experience made wallet onboarding and minting easy">
                    <p className="text-black">
                        To interact on the blockchain, you need a crypto wallet. For Art Basel Miami Beach, we employed Souvie, a custom-built app to onboard attendees and help them set up a Tezos wallet. More than 1,300 NFTs were minted by visitors to the exhibition, powering even more activity on one of the world’s most popular blockchains for digital art.
                    </p>
                    <Button label="Experience Souvie at ABMB" />
                </CallToAction>
                <Cards />
                <StatsVideoSection />
            </main>
        </>
    )
}

export default App

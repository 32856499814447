import React from 'react'
import { CenteredVideo } from '../CenteredVideo'
import { Stats } from '../Stats'

const StatsVideoSection = () => {
    return (
        <div className="relative -top-[8rem]">
            <div className="pt-14 pb-10 lg:pt-28 lg:pb-20 bg-black">
                <Stats color="text-primary" />
            </div>
            <div className=" bg-xblack w-full pt-10 pb-28 lg:pt-28 lg:pb-[200px] flex flex-col  space-y-10 lg:space-y-20">
                <div className="custom-container w-full lg:w-3/5">
                    <h3 className="text-light text-left lg:text-center lg:mx-auto">
                        Learn more about the live minting experience below:
                    </h3>
                </div>
                <CenteredVideo video_url="https://player.vimeo.com/video/779724428?h=4e58708cbb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
            </div>
        </div>
    )
}

export { StatsVideoSection }

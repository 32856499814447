import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Fade } from 'react-awesome-reveal'

const Cards = () => {
    return (
        <div className="custom-container flex flex-col lg:flex-row lg:space-x-20 lg:space-y-0 space-x-0 space-y-10 lg:pb-28 pb-14 relative -top-[8rem]">
            <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                <Fade direction="up" triggerOnce delay={100}>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        layout="constrained"
                        placeholder="blurred"
                        aspectRatio={1 / 1}
                        alt="Card"
                        src={'../../images/cards/perforamnce-nft-1.png'}
                        formats={['auto', 'webp', 'avif']}
                    />
                    <h3>A selection of the mints from ‘Stepping Stones’</h3>
                    <p className="text-medium">Artist: Tyler Boswell</p>
                </Fade>
            </div>
            <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                <Fade direction="up" triggerOnce delay={200}>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        layout="constrained"
                        placeholder="blurred"
                        aspectRatio={1 / 1}
                        alt="Card"
                        src={'../../images/cards/perforamnce-nft-2.png'}
                        formats={['auto', 'webp', 'avif']}
                    />
                    <h3>A selection of the mints from ‘Udnē’</h3>
                    <p className="text-medium">Artist: DistCollective</p>
                </Fade>
            </div>
            <div className="flex flex-col w-full lg:w-1/3 space-y-4">
                <Fade direction="up" triggerOnce delay={300}>
                    <StaticImage
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px',
                        }}
                        layout="constrained"
                        placeholder="blurred"
                        aspectRatio={1 / 1}
                        alt="Card"
                        src={'../../images/cards/perforamnce-nft-3.png'}
                        formats={['auto', 'webp', 'avif']}
                    />
                    <h3>A selection of the mints from ‘Whispers (In Code)’</h3>
                    <p className="text-medium">Artist: Ivona Tau</p>
                </Fade>
            </div>
        </div>
    )
}

export { Cards }

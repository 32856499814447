import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ArrowSteps } from './ArrowSteps'
import { Fade } from 'react-awesome-reveal'

const Steps = () => {
    return (
        <div className="lg:container mx-auto relative -top-[8rem]">
            <div className="flex flex-col lg:flex-row space-y-[35px] space-x-0 lg:space-y-0 lg:space-x-[35px] items-center px-[76px] py-14 lg:px-0 lg:py-28">
                <div className="w-full lg:w-1/4">
                    <Fade direction="up" triggerOnce delay={100}>
                        <StaticImage
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            layout="fullWidth"
                            placeholder="blurred"
                            alt="Step 1"
                            src={'../../images/steps/Performance1.png'}
                            formats={['auto', 'webp', 'avif']}
                            className="runded_steps"
                        />
                    </Fade>
                </div>
                <ArrowSteps />
                <div className="w-full lg:w-1/4">
                    <Fade direction="up" triggerOnce delay={200}>
                        <StaticImage
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            layout="fullWidth"
                            placeholder="blurred"
                            alt="Step 2"
                            src={'../../images/steps/Performance2.png'}
                            formats={['auto', 'webp', 'avif']}
                            className="runded_steps"
                        />
                    </Fade>
                </div>
                <ArrowSteps />
                <div className="w-full lg:w-1/4">
                    <Fade direction="up" triggerOnce delay={300}>
                        <StaticImage
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            layout="fullWidth"
                            alt="Step 3"
                            placeholder="blurred"
                            src={'../../images/steps/Performance3.png'}
                            formats={['auto', 'webp', 'avif']}
                            className="runded_steps"
                        />
                    </Fade>
                </div>
                <ArrowSteps />
                <div className="w-full lg:w-1/4">
                    <Fade direction="up" triggerOnce delay={400}>
                        <StaticImage
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            layout="fullWidth"
                            placeholder="blurred"
                            alt="Step 1"
                            src={'../../images/steps/Performance4.png'}
                            formats={['auto', 'webp', 'avif']}
                            className="runded_steps"
                        />
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export { Steps }

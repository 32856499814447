import React from 'react'

const ArrowSteps = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="9"
            fill="none"
            viewBox="0 0 42 9"
            className="hidden lg:block"
        >
            <path
                fill="#0F62FF"
                d="M41.168 4.854a.5.5 0 000-.708L37.986.964a.5.5 0 10-.707.708L40.108 4.5l-2.829 2.828a.5.5 0 10.707.708l3.182-3.182zM.815 5h40V4h-40v1z"
            ></path>
        </svg>
    )
}

export { ArrowSteps }
